<template>
  <div class="pro-type-list">
    <div>
      <van-tabs v-if="newCategoryList.length" v-model="newAcitve" :swipeable="true" sticky @change="change">
        <van-tab v-for="(item, index) in newCategoryList" :key="item.uuid"  title-class="type-name">
          <template #title>
            <div class="item">
              <img :src="item.filePath" alt="">
              <div class="type-name" :class="{ active: newAcitve == index }">{{ item.typeName }}</div>
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </div>
    <div class="sideSlip_"></div>
  </div>
</template>

<script>
export default {
  name: 'ProTypeList',
  props: {
    active: {
      type: Number,
      default: 0
    },
    categoryList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      newCategoryList: [],
      newAcitve: 0
    }
  },

  watch: {
    categoryList(newVal) {
      this.newCategoryList = []
      this.newCategoryList = newVal
    },
    active: {
      immediate: true,
      handler(val, oldVal) {
        this.newAcitve = val
      }
    }
  },
  methods: {
    change(e) {
      this.$emit('cur-proActive', this.newCategoryList[e]?.typeName)
      this.$store.commit('SET_PRO_ACTIVE', e)
    }
  },
  deactivated() {
    this.newCategoryList = []
    this.newAcitve =  0
  },
}
</script>

<style lang="less" scoped>
.pro-type-list {
  height: 100%;
  background-color: #f1f1f1;
  padding: 7px 0 0;

  .item {
    width: 68px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > img {
      width: 46px;
      height: 46px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
      border-radius: 50%;
      margin-bottom: 12px;
    }
    .type-name {
      width: 68px;
      white-space: nowrap;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #969696;
      line-height: 21px;
      overflow:hidden;
      text-align: center;
      text-overflow:ellipsis;
    }

    .active {
      color: #ff1448;
    }
  }

  /deep/.van-tabs__wrap {
    height: 100px;
    .van-tabs__line {
      display: none;
    }
    .van-tabs__nav{
      .van-tab{
        padding: 0!important;
      }
    }
  }

  /deep/.van-tabs__content {
    display: none;
  }
}
</style>
