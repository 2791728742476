<template>
	<van-nav-bar left-arrow>
		<template #left>
			<img width="28px" height="28px" src="~@/assets/img/order/back-left.png" alt="" @click="goBack(1)">
		</template>
		<template #title>
			<div class="search-top">
				<img class="left" src="@/assets/img-v3/new-home/searchIcon.png" @click="searchClick">
				<input v-model="searchName" placeholder="请输入想要搜索的产品" class="search-input" @keyup.enter="searchClick">
				<van-icon name="scan" @click.stop="scanCode" class="right" color="#1CDDA0"  size="20" />
				<!-- <img @click="handleVoice" class="right" src="@/assets/img/home/voice.png" /> -->
			</div>
		</template>
		<template #right>
			<img width="48px" height="48px" src="~@/assets/img/order/back-home.png" alt="" @click="goBack(2)">
		</template>
	</van-nav-bar>
</template>

<script>
	import {
		Toast
	} from 'vant'
	export default {
		name: 'TopNavBar',
		props: {
			clearSearchName: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				searchName: ''
			}
		},
		watch: {
			clearSearchName(newValue, oldValue) {
				if (newValue) {
					this.searchName = ''
				}
			}
		},
		methods: {
			scanCode() {
				if(!this.$store.state.wxData.appId){
					return
				}
				
				wx.config({
					debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
					appId:this.$store.state.wxData.appId, // 必填，公众号的唯一标识
					timestamp:this.$store.state.wxData.timestamp, // 必填，生成签名的时间戳
					nonceStr:this.$store.state.wxData.nonceStr, // 必填，生成签名的随机串
					signature:this.$store.state.wxData.signature, // 必填，签名
					jsApiList: ["scanQRCode"] // 必填，需要使用的JS接口列表
				});
				wx.ready(function(res) {
					wx.scanQRCode({
						needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
						scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
						success: function(res) {
							var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
						}
					});
				})
			},
			goBack(type) {
				switch (type) {
					case 1:
						this.$router.go(-1)
						break
					case 2:
						this.$router.push({
							name: 'Home',
							query: {
								shopUuid: this.$store.state.shopUuid
							}
						})
						break
				}
			},
			searchClick(e) {
				this.$emit('search', this.searchName)
				this.bus.$emit('prtName', this.searchName)
			},
			handleVoice() {
				Toast('网页暂不支持')
			}
		}
	}
</script>

<style lang="less" scoped>
	.search-top {
		width: 220px;
		height: 33px;
		background: #f4f4f4;
		border-radius: 16px;
		display: flex;
		align-items: center;
		position: relative;

		.left {
			margin: 0 8px 0 13px;
		}

		.right {
			position: absolute;
			right: 12px;
		}

		&>.search-input {
			width: 150px;
			height: 33px;
			line-height: 33px;
			white-space: nowrap;
			font-size: 13px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #646464;
			border: none;
			outline: none;
			background: transparent;
			text-align: left;

			&::placeholder {
				color: #646464;
			}
		}

		&>img {
			width: 17px;
			height: 17px;
		}
	}

	.home-logo {
		height: 30px;
		margin-top: 8px;
	}

	.home-left,
	.home-right {
		width: 29px;
		height: 29px;
	}

	[class*="van-hairline"]::after {
		border: none;
	}
</style>
