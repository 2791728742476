<template>
	<div class="recommend-container">
		<div>
			<div class="product-list" v-if="proList.length > 0">
				<ProductCard v-for="(item,index) in proList" :key="index" :info="item" @getInfo="getInfo" />
			</div>
		</div>
	</div>
</template>

<script>
	import ProductCard from '@c/common/ProductCard.vue'
	export default {
		name: 'RecommendList',
		components: {
			ProductCard
		},
		props: {
			page: {
				type: Number,
				default: 1
			},
			proList: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				// prtCode: '',
				// prtName: '',
			}
		},
		computed:{
			
		},

		created() {
			// this.bus.$on('prtCode', data => {
			//   this.prtCode = data
			//   this.getNewProduct()
			// })

			// this.bus.$on('prtName', data => {
			//   this.prtName = data
			//   this.getNewProduct()
			// })
		},

		methods: {
			getInfo(data) {
				this.$emit('getInfos', data)
			},
		}
	}
</script>

<style lang="less" scoped>
	.recommend-container {
		.product-list {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin: 10px;
		}

		.title {
			background: #ffffff;
			width: 100%;
			height: 47px;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			&>img {
				width: 20px;
				height: 20px;
				margin: 0 8px 0 28px;
			}

			&>span {
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #323232;
				line-height: 10px;
			}
		}

		.loading {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
</style>
